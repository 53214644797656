import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import Navbar2 from "../components/navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  CrmUsers,
  GiveAccess,
  HandoverLead,
  changepassword,
  verifyPassword,
} from "../_helper/apiCall/leadServices";
import { toast } from "react-toastify";
import Download from "./downloadexcel";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { UpdateLead } from "../_helper/apiCall/leadServices";

function Giveaccess() {
  const [crmuser, setCrmuser] = useState();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(true);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);
  const toggle4 = () => setModal4(!modal4);
  const [newpassword, setNewpassword] = useState();
  const [password, setPassword] = useState();
  const [id, setId] = useState();
  const [name, setName] =useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [oldemail, setOldemail] = useState()

  const change = async (e) => {
    e.preventDefault();
    let obj = {
      newpassword: newpassword,
    };
    if (!newpassword) {
      toast.error("Please write a new password");
      return;
    }
    let res = await changepassword(id, obj);
    if (res?.status === 1) {
      setModal(false);
      toast.success(res?.message);
      return;
    }
    toast.error(res?.message);
    return;
  };

  const Submit = async (e) => {
    e.preventDefault();
    let obj = {
      id, 
      user:email , 
      name , 
      olduser:oldemail, 
      phone
    };
    if (
      !name ||
      !phone ||
      !email
    ) {
      toast.error("all fields required");
      return;
    }
    let res = await HandoverLead(obj);
    if (res?.status === 1) {
      // navigate("/follow-up");
      setModal4(false);
      toast.success(res.message);
      return;
    }
    toast.error(res?.message);
    return;
  };
  

  useEffect(() => {
    Aos.init({ duration: 1000 });
    (async () => {
      const data = await CrmUsers();
      if (data) {
        setCrmuser(data?.data);
      } else {
        setCrmuser([]);
      }
    })();
  }, []);
  const onChange = async (id, allowLogin) => {
    let access = await GiveAccess(id, { allowLogin: allowLogin });
    if (access) {
      toast(access.message);
      const data = await CrmUsers();
      if (data) {
        setCrmuser(data?.data);
      } else {
        setCrmuser([]);
      }
    }
  };
  const verifyUserPassword = async (e) => {
    e.preventDefault();
    let res = await verifyPassword({ password: password });
    console.log("res", res);
    if (res.status) {
      setModal2(!modal2);
    } else {
      toast.error(res.message);
    }
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Give Access</title>
        </Helmet>
      </HelmetProvider>
      <Navbar2 />
      <div className="m-3">
        <div className="margintop table">
          <Button
            color="info"
            onClick={(e) => {
              e.preventDefault();

              toggle3();
            }}
            size="sm"
          >
            Download Excel
          </Button>
          {crmuser && crmuser.length > 0 ? (
            <Table
              striped
              responsive
              className="text-center"
              data-aos="fade-up"
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {crmuser.map((i, index) => (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>{i.name}</td>
                    <td>{i.email}</td>
                    <td>{i.phone}</td>
                    <td>
                      <ButtonGroup>
                        <Button
                          color={i.allowLogin == 0 ? "success" : "danger"}
                          onClick={(e) => {
                            e.preventDefault();
                            onChange(i.id, i.allowLogin == 0 ? 1 : 0);
                          }}
                          size="sm"
                        >
                          {i.allowLogin == 0 ? "Access" : "Denied"}
                        </Button>
                        <Button
                          color="info"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(i.id);
                            toggle();
                          }}
                          size="sm"
                        >
                          Change Password
                        </Button>
                        <Button
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(i.id);
                            setEmail(i.email)
                            setName(i.name)
                            setPhone(i.phone)
                            setOldemail(i.email)
                            toggle4();
                          }}
                          size="sm"
                        >
                        Handover
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <>No record found</>
          )}
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle} className="adddeal-popup">
          Change Your Password
        </ModalHeader>
        <ModalBody className="adddeal-popup m-3">
          <Input
            id="newpassword  "
            value={newpassword}
            onChange={(e) => {
              setNewpassword(e.target.value);
            }}
            placeholder="Enter Your new Password"
            name="newpassword"
            type="password"
            autoComplete="off"
          />

          <Button className="mt-4" onClick={change}>
            Save Changes
          </Button>
        </ModalBody>
      </Modal>
      {/* ============================Save Excel================== */}

      <Modal isOpen={modal3} toggle={toggle3} backdrop="static">
        <ModalHeader toggle={toggle3} className="adddeal-popup">
          Download Excel
        </ModalHeader>
        <ModalBody className="adddeal-popup">
          <Download />
        </ModalBody>
      </Modal>
      {/* ===============================authentication===================== */}
      <Modal
        isOpen={modal2}
        toggle={toggle2}
        size="lg"
        style={{ maxWidth: "70%", width: "20%" }}
        backdrop="static"
      >
        <ModalBody className="adddeal-popup m-3">
          <h5 style={{ textAlign: "center", marginBottom: "5%" }}>
            Give Your Password{" "}
          </h5>
          <Input
            id="password  "
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Enter Your Password"
            name="password"
            type="password"
            autoComplete="off"
          />
          <Button className="mt-4" onClick={verifyUserPassword} color="success">
            Verify
          </Button>
        </ModalBody>
      </Modal>

      {/* ===============================authentication===================== */}

      <Modal
     isOpen={modal4}
     toggle={toggle4}
     size="lg"
     style={{ maxWidth: "70%", width: "20%" }}
     backdrop="static"
   >
       <ModalHeader toggle={toggle4} className="adddeal-popup">
       Handover Lead to another user
   </ModalHeader>
     <ModalBody className="adddeal-popup m-3">
       <Input
         value={name}
         onChange={(e) => {
           setName(e.target.value);
         }}
         placeholder="Enter Your Name"
         name="name"
         type="text"
         autoComplete="off"
       />
       <br />
       <Input
        value={email}
             onChange={(e) => {
              setEmail(e.target.value);
            }}
       type="email"
       placeholder="Enter Your Email"
       />
       <br />
          <Input
           value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
   type="number"
   placeholder="Enter Your Phone Number"
   />
       <Button className="mt-4" color="success" onClick={Submit}>
         Assign
       </Button>
     </ModalBody>
   </Modal>
    </>
  );
}

export default Giveaccess;
