import RestMethod from "./rest_Methods";


export const AddLead=async(data) =>{
    let url="/add-lead"
    try{
const response = await RestMethod.POST(url,data)
return response.data
    }catch(error){
        console.log("error detected while fetching data from api", error);
        return null;
    }
};

export const DailyReportData= async (username, reportdate ,page)=>{
    let url=`/dailyreport?page=${page}&limit=15`
    if(username){
        url+=`&user=${username}`
    }
    if(reportdate){
        url+=`&reportdate=${reportdate}`
    }
    try {
        const response= await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null
    }
}

export const AllLead= async (leadstatus,page,filter)=>{
    let url=`/user-lead?&page=${page}&limit=15`
    if(leadstatus){
        url+=`&status=${leadstatus}`
    }
    if(filter){
        url+=`${filter}`
    }
    try {
        const response= await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null
    }
}

export const todayAppointment= async (leadstatus)=>{
    let url="/today-appointment"
    if(leadstatus){
        url+=`?status=${leadstatus}`
    }
    try {
        const response= await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null
    }
}

export const Getbyid= async(id)=>{
    let url="/read-by-id/"+id;
    try {
        const response= await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null;
    }
}

export const reschedulebyid= async(id,obj)=>{
    let url="/reschedule/"+id;
    try {
        const response= await RestMethod.PUT(url,obj)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null;
    }
}

export const HandoverLead= async (data)=>{
 const url="/handover-another-user/"
    try {
        const response = await RestMethod.PUT(url,data)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null
    }
}

export const UpdateLead= async (id, data)=>{
    const url="/update/"+id
    try {
        const response = await RestMethod.PUT(url,data)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null
    }
}

export const changepassword=async (id,data)=>{
    const url="/change-password/"+id
    try {
        const response=await RestMethod.PUT(url,data)
return response.data        
    } catch (error) {
        console.log("error detected while fetching data from api", error);
        return null
    }
}

export const UpdateUser= async (data)=>{
    const url="/update-user/"
    try {
        const response = await RestMethod.PUT(url,data)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return null
    }
}



export const Carddata= async ()=>{
    let url="card-data"
    try {
        const response= await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error);
        return null
    }
}

export const Allusers=async()=>{
    let url="/crmusers"
    try {
        const response= await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error)
        return  null
    }
}

export const GiveAccess = async (id, data)=>{
const url="/giveaccess/"+id
try {
    const response = await RestMethod.PUT(url,data)
    return response.data
} catch (error) {
    console.log("error detected while fetching data from api", error);
    return null
}
}
export const verifyPassword = async (data)=>{
    const url="/verify-password"
    try {
        const response = await RestMethod.POST(url,data)
        return response.data
    } catch (error) {
        console.log("error detected while fetching data from api", error);
        return null
    }
    }

export const CrmUsers= async ()=>{
    let url= "/crmusers"
    try {
        const response = await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error dected while fetching data from api" , error);
        return null
    }
}

export const countForPi= async ()=>{
    let url= "/monthly-data"
    try {
        const response = await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error dected while fetching data from api" , error);
        return null
    }
}
export const countForbar= async ()=>{
    let url= "/monthly-data-for-bar-chart"
    try {
        const response = await RestMethod.GET(url)
        return response.data
    } catch (error) {
        console.log("error dected while fetching data from api" , error);
        return null
    }
}